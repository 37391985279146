<template>
  <div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }" @click="reall">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">气体监测</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div id="forms_div2">
      <el-tabs v-model="activeName" tab-position="left" @tab-click="handleClick">
        <el-tab-pane label="气体监测数据" name="a"><Qitidata></Qitidata></el-tab-pane>
        <el-tab-pane label="预警短信管理" name="b"><Qitismssend></Qitismssend></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Qitidata from '@/views/qiti/qitidata'
import Qitismssend from '@/views/qiti/qitismssend'
import Header from "@/views/header";
export default {
  name: "qiticheck",
  components: {Header, Qitidata, Qitismssend},
  data(){
    return{
      activeName: 'a'
    }
  },
  created() {
    console.log(this.$route.params)
    if (!this.$route.params.key) {
      this.$router.push("/manage")
    }
    if (sessionStorage.getItem('formsnames')){
      this.activeName = sessionStorage.getItem('formsnames');
    }
  },
  methods:{
    handleClick(tab, event){
      console.log(tab)
      // 点击tab后触发事件，修改显示页面，将状态保存在sessionStorage里面
      sessionStorage.setItem('formsnames', tab.props.name)
    },
    reall(){
      sessionStorage.removeItem('formsnames');
    },
  }
}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  justify-content: space-between;
}
#forms_div2{
  border-radius: 5px;
  margin: 0 auto;
  background-color: white;
  width: 95%;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 500px;
}
</style>