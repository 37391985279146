<template>
  <div>
<!--    工区选择-->
    <div>
      <el-select v-model="bdstname" size="mini" clearable placeholder="请选择工点" @change="getSmsUser" style="margin-right: 20px">
        <el-option
            v-for="item in bdstnames"
            :key="item.bdst"
            :label="item.bdst"
            :value="item.bdst"
        />
      </el-select>
      <el-button type="success" size="mini" @click="addSmsSned()">新增</el-button>
    </div>
<!--    用户展示-->
    <div style="margin-top: 20px">
      <vxe-table
          border
          resizable
          :align="allAlign"
          :data="sendSmsUser"
          size="small"
          highlight-hover-row
          ref="xTable1"
          :export-config="{}"
          :edit-config="{trigger: 'dblclick', mode: 'cell'}"
      >
        <vxe-column field="bdst" title="单位"></vxe-column>
        <vxe-column field="sendname" title="姓名"></vxe-column>
        <vxe-column field="tel" title="联系方式" :edit-render="{autofocus: '.vxe-input--inner'}">
          <template #edit="{ row }">
            <vxe-input v-model="row.tel" type="text" @blur="updateSendUser(row)"></vxe-input>
          </template>
        </vxe-column>
        <vxe-column field="detailbdst" title="报警工点" :edit-render="{autofocus: '.vxe-input--inner'}">
          <template #edit="{ row }">
            <vxe-input v-model="row.detailbdst" type="text" @blur="updateSendUser(row)"></vxe-input>
          </template>
        </vxe-column>
        <vxe-column title="操作" width="100" show-overflow>
          <template #default="{ row }">
            <vxe-button size="mini" status="danger" @click="delSendUser(row)">删除</vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
<!--    新增人员-->
    <div>
      <el-dialog
          v-model="dialogVisible"
          title="新增预警短信人员"
          width="50%">
        <el-form label-width="120px">
          <el-form-item label="姓名">
            <el-input v-model="name" />
          </el-form-item>
          <el-form-item label="单位">
            <el-select v-model="addbdst" clearable placeholder="请选择工点" @change="getSmsUser" style="margin-right: 20px">
              <el-option
                  v-for="item in bdstnames"
                  :key="item.bdst"
                  :label="item.bdst"
                  :value="item.bdst"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="tel" @blur="yzTel(tel)"/>
          </el-form-item>
          <el-form-item label="预警工点">
            <el-input v-model="detailbdst" />
          </el-form-item>
        </el-form>
        <div style="margin-left: 80px">
          <el-button size="mini" type="danger" v-for="(item, i) in sendOper" style="margin-right: 30px" @dblclick = "getSendoper(item)">{{ item }}</el-button>
          <el-button size="mini" type="success" @click="clearOper">清空预警工点</el-button>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancelDio">取消</el-button>
            <el-button type="primary" @click="addupSnedUser">提交</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  name: "qitismssend",
  inject: ['reload'],
  data(){
    return{
      bdstname: '',
      bdstnames: [
        {
          bdst: '项目公司',
          bdstname: '项目公司'
        },
        {
          bdst: '五标四工区',
          bdstname: '五标四工区'
        },
        {
          bdst: '五标七工区',
          bdstname: '五标七工区'
        },
        {
          bdst: '二标二工区',
          bdstname: '二标二工区'
        }
      ],
      allAlign: 'center',
      sendSmsUser: [],
      dialogVisible: false,
      addbdst: '',
      name: '',
      tel: '',
      detailbdst: '',
      sendOper: []
    }
  },
  created() {
    this.getSmsUser()
  },
  methods:{
    getSmsUser(){
      this.axios.post("/qitisend/sendByBdst", (response) => {
        this.sendSmsUser = response.obj;
      },{
        bdst: this.bdstname
      })
    },
    addSmsSned(){
      this.dialogVisible = true;
      this.axios.get("/qitisend/getSendOper", (response) => {
        this.sendOper = response.obj;
      })
    },
    delSendUser(row){
      this.axios.post("/qitisend/delUserById", (response) => {
        if (response.obj){
          ElMessage({
            message: '删除成功',
            type: 'success',
          });
          this.reload();
        }
      },{
        id: row.id,
      })
    },
    updateSendUser(row){
      this.axios.post("/qitisend/updateById", (response) => {
        if (response.obj){
          this.reload();
        }
      },{
        id: row.id,
        tel: row.tel,
        detailbdst: row.detailbdst
      })
    },
    cancelDio(){
      this.dialogVisible = false;
      this.name = '';
      this.addbdst = '';
      this.detailbdst = '';
      this.tel = ''
    },
    getSendoper(snedoper){
      if (this.detailbdst === ''){
        this.detailbdst = this.detailbdst + snedoper;
      }else{
        this.detailbdst = this.detailbdst + ',' + snedoper;
      }
    },
    clearOper(){
      this.detailbdst = ''
    },
    addupSnedUser(){
      this.axios.post("/qitisend/insertSendUser", (response) => {
        if (response.obj){
          ElMessage({
            message: '新增成功',
            type: 'success',
          });
          this.reload();
        }
      },{
        name: this.name,
        bdst: this.addbdst,
        detailbdst: this.detailbdst,
        tel: this.tel
      })
    },
    yzTel(tel){
      const telStr =
          /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
      if (tel === ""){
        ElMessage.error("请输入手机号!")
      }
      if (tel !== "" && !telStr.test(tel)) {
        ElMessage.error("手机号有误,请检查输入的手机号!")
      }
    }
  }
}
</script>

<style scoped>

</style>