<template>
  <div>
<!--    工点、时间选择-->
    <div>
<!--      工点选择-->
      <el-select v-model="opername" size="mini" clearable placeholder="请选择工点" @change="getQitiData()" style="margin-right: 20px">
        <el-option
            v-for="item in opernames"
            :key="item.opername"
            :label="item.opername"
            :value="item.opername"
        />
      </el-select>

<!--      选择时间-->
      <el-date-picker
          v-model="time"
          type="date"
          placeholder="选择日期"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          style="margin-right: 50px"
          size="mini"
          @change="getQitiData()"
      >
      </el-date-picker>
    </div>
<!--    数据展示-->
    <div style="margin-top: 30px">
      <vxe-table
          border
          resizable
          :align="allAlign"
          :data="qitiData"
          size="small"
          highlight-hover-row
          :span-method="mergeRowMethod"
          ref="xTable1"
          :export-config="{}"
      >
        <vxe-column field="opername" title="工点名称"></vxe-column>
        <vxe-column field="addressname" title="安装位置"></vxe-column>
        <vxe-column field="updatetime" title="检测时间"></vxe-column>
        <vxe-column field="co" title="CO"></vxe-column>
        <vxe-column field="wasi" title="CH4(瓦斯)"></vxe-column>
        <vxe-column field="hhs" title="H₂S"></vxe-column>
        <vxe-column title="操作" width="100" show-overflow>
          <template #default="{ row }">
            <vxe-button size="mini" status="primary" @click="getQitiDataMx(row)">查看明细</vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
<!--    气体监测明细表-->
    <div>
      <el-dialog
          v-model="dialogVisible"
          :title=this.mxname
          width="60%">
        <vxe-table
            border
            resizable
            :align="allAlign"
            :data="qitiDataMx"
            size="small"
            highlight-hover-row
            :row-style="cellStyle"
            ref="xTable1"
            :export-config="{}"
        >
          <vxe-column field="updatetime" title="检测时间"></vxe-column>
          <vxe-column field="qititype" title="气体类型"></vxe-column>
          <vxe-column field="qitinum" title="检测数值"></vxe-column>
          <vxe-column field="qitidanwei" title="单位"></vxe-column>
          <vxe-column field="things" title="报警信息" show-overflow></vxe-column>
        </vxe-table>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {setNowDate} from "@/utils/time";

export default {
  name: "qitidata",
  data(){
    return{
      opername: '',
      time: '',
      opernames: [],
      qitiData: [],
      allAlign: 'center',
      dialogVisible: false,
      mxname: '',
      qitiDataMx: []
    }
  },
  created() {
    this.time = setNowDate(new Date());
    this.axios.get('/qitioper/getAllOper', (response) => {
      this.opernames = response.obj;
    });
    this.getQitiData();
  },
  methods:{
    getQitiData(){
      this.axios.post('/qiticheck/getQitiData', (response) => {
        this.qitiData = response.obj;
      },{
        opername: this.opername,
        time: this.time
      })
    },
    mergeRowMethod ({ row, _rowIndex, column, visibleData }) {
      const fields = ['opername']
      const cellValue = row[column.property]
      if (cellValue && fields.includes(column.property)) {
        const prevRow = visibleData[_rowIndex - 1]
        let nextRow = visibleData[_rowIndex + 1]
        if (prevRow && prevRow[column.property] === cellValue) {
          return {rowspan: 0, colspan: 0}
        } else {
          let countRowspan = 1
          while (nextRow && nextRow[column.property] === cellValue) {
            nextRow = visibleData[++countRowspan + _rowIndex]
          }
          if (countRowspan > 1) {
            return {rowspan: countRowspan, colspan: 1}
          }
        }
      }
    },
    getQitiDataMx(row){
      const month = this.time.substring(0,7);
      this.mxname = row.opername + "-" + row.addressname + "-" + month + "-气体监测明细表";
      this.dialogVisible = true;
      this.axios.post('/qiticheck/getQitiDataMx', (response) => {
        this.qitiDataMx = response.obj
      },{
        opername: row.opername,
        time: month,
        addressname: row.addressname
      })
    },
    cellStyle ({ row }) {
      console.log('测试===' + row.things)
      if (row.things === '二级报警') {
        console.log(row.row.things)
        return {
          backgroundColor: 'red',
          color: '#ffffff'
        }
      }
    },
  }
}
</script>

<style scoped>


</style>